import React from "react"
import toast from "react-hot-toast"

export function warn ({
  id, title, message
}: {id: string, title: string, message: string}) {
  return toast.custom(
    WarningToast({
      title,
      message
    }),
    { id }
  )
}

export function WarningToast ({ title, message }: { title: string, message:string }) {
  return (
    <div id="toast-warning" className="flex items-start max-w-sm px-2 py-1 border-orange-200 border text-gray-500 bg-white shadow gap-x-2" role="alert">
      <div className="inline-flex items-center justify-center flex-shrink-0 h-7 text-orange-500">
        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd"></path>
        </svg>
      </div>
      <div className="flex flex-col">
        <div className="text-base font-medium" >{title}</div>
        <div className="text-sm font-normal">{message}</div>
      </div>
    </div>
  )
}

export function SuccessToast ({
  message,
  onCloseHandler
}: { message:string, onCloseHandler: () => void }) {
  return (
    <div id="toast-success" className="flex items-start max-w-sm px-2 py-1 border-green-200 border text-gray-500 bg-white shadow gap-x-2" role="alert">
      <div className="inline-flex items-center justify-center flex-shrink-0 h-7 text-green-500">
        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"></path></svg>
      </div>
      <div className="text-sm font-normal">{message}</div>
      <button type="button" onClick={onCloseHandler} className="ml-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex h-8 w-8" data-dismiss-target="#toast-success" aria-label="Close">
        <span className="sr-only">Close</span>
        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
      </button>
    </div>
  )
}
